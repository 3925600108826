import React, { Fragment, useEffect, useState } from "react"
import {
  FlatItemContainer,
  MobileContainer,
  SearchContainer,
} from "./searchStyles"
import { Accordion, Button, Col, Container, Row } from "react-bootstrap"

import Fuse from "fuse.js"
import posed, { PoseGroup } from "react-pose"
import { MobileButton } from "../shared/button/button"
import ModalComponent from "../shared/modal/modal"
import ShowMore from "@tedconf/react-show-more"
import { injectIntl } from "gatsby-plugin-intl"
import img360 from "../../images/z-17/icons8-360-view-50.png"

const PoseWrapper = posed.div({
  enter: { opacity: 1, delay: ({ i }) => i * 100 },
  exit: { opacity: 0, delay: 50 },
})

function Search({ intl, list, estate }) {
  const [selectValue, setSelectValue] = useState({
    building: ["", 1],
    rooms: ["", 5],
    space: ["", 12],
    floor: ["", 3],
    // windows: ["", 6],
    facilities: ["", 6],
    sold: ["", 13],
    types: ["", 14],
  })

  const [selectCategory, setSelectCategory] = useState([])
  const [results, setResults] = useState(list)
  const [reset, callReset] = useState()
  const [activeFilters, setActiveFilters] = useState({})
  const [activeFiltersCounter, setActiveFiltersCounter] = useState(0)

  const addID = item => {
    return localStorage.setItem("id", item)
  }

  const optionsList = {
    building: [
      { value: "A", label: "A", id: "building" },
      // { value: "B", label: "B", id: "building" },
      // { value: "C", label: "C", id: "building" },
    ],
    rooms: [
      { value: "1", label: "1", id: "rooms" },
      { value: "2", label: "2", id: "rooms" },
      { value: "3", label: "3", id: "rooms" },
      // { value: "4", label: "4", id: "rooms" },
    ],

    space: [
      {
        value: "small",
        label: "20-34m2",
        id: "space",
      },
      {
        value: "medium",
        label: "35-49m2",
        id: "space",
      },
      {
        value: "large",
        label: "50-65m2",
        id: "space",
      },
      // {
      //   value: "huge",
      //   label: estateName === "wiczlino" ? "" : "60-75m2",
      //   id: "space",
      // },
    ],

    sold: [
      {
        value: "",
        label: "sold",
        id: "sold",
      },
    ],

    floor: [
      { value: "P0", label: "P0", id: "floor" },
      { value: "P1", label: "P1", id: "floor" },
      { value: "P2", label: "P2", id: "floor" },
      { value: "P3", label: "P3", id: "floor" },
    ],
    facilities: [
      { value: "ogr", label: "ogródek", id: "facilities" },
      { value: "balkon", label: "balkon", id: "facilities" },
      { value: "taras", label: "taras", id: "facilities" },
      // { value: "dwaogr", label: "dwa ogrody", id: "facilities" },
      // { value: "dwabalkony", label: "dwa balkony", id: "facilities" },
    ],
    windows: [
      {
        value: "północ",
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.north",
        })}`,
        id: "windows",
      },
      {
        value: "południe",
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.south",
        })}`,
        id: "windows",
      },
      {
        value: "wschód",
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.east",
        })}`,
        id: "windows",
      },
      {
        value: "zachód",
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.west",
        })}`,
        id: "windows",
      },
    ],
    types: [
      {
        value: "residential",
        label: intl.formatMessage({
          id: "offer.puck.list.options.type.residential",
        }),
        id: "types",
      },
      // {
      //   value: "apartment",
      //   label: intl.formatMessage({
      //     id: "offer.puck.list.options.type.apartment",
      //   }),
      //   id: "types",
      // },
      {
        value: "service",
        label: intl.formatMessage({
          id: "offer.janowo.list.options.type.service",
        }),
        id: "types",
      },
    ],
  }

  const [fuseOptions, setFuseOptions] = useState({
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    tokenize: true,
    findAllMatches: true,
    keys: selectCategory,
  })

  const handleSelectValue = (e, numId, val) => {
    const checkCat = () => {
      let obTemp = Object.values(selectValue)
      obTemp.map(element => {
        element[1] === numId &&
          element[0].replace(/\s/g, "").length === 0 &&
          categoryTemp.splice(categoryTemp.indexOf(numId), 1)
      })
    }
    let categoryTemp = selectCategory
    let valueTemp = selectValue
    let re = new RegExp(val, "g")
    valueTemp[e.target.dataset.cat][0].includes(val)
      ? (valueTemp[e.target.dataset.cat][0] = valueTemp[
          e.target.dataset.cat
        ][0].replace(re, ""))
      : (valueTemp[e.target.dataset.cat][0] += val + " ")
    setSelectValue(valueTemp)
    !categoryTemp.includes(numId) ? categoryTemp.push(numId) : checkCat()
    setSelectCategory(categoryTemp)

    setFuseOptions({
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      tokenize: true,
      findAllMatches: true,
      keys: categoryTemp,
    })
  }

  const handleReset = () => {
    callReset(!reset)
    setActiveFilters({})
    setActiveFiltersCounter(0)
    setTempResults(list)
    setSelectCategory([])
    setSelectValue({
      building: ["", 1],
      rooms: ["", 5],
      space: ["", 12],
      floor: ["", 3],
      // windows: ["", 6],
      facilities: ["", 6],
      types: ["", 14],
    })
    setResults(list)
  }

  const [tempResults, setTempResults] = useState(list)

  const [showFilters, setShowFilters] = useState(false)

  const handleSearch = () => {
    let fuse = new Fuse(list, fuseOptions)
    selectCategory.forEach(elem => {
      for (let key in selectValue) {
        if (selectValue[key][1] === parseInt(elem)) {
          setTempResults(fuse.search(selectValue[key][0]))
          fuse = new Fuse(fuse.search(selectValue[key][0]), fuseOptions)
        }
      }
    })

    selectCategory.length < 1 && setResults(list)
  }

  useEffect(() => {
    setResults(tempResults)
  }, [tempResults])

  const gtag_report_ask_conversion = () => {
    if (window.gtag_report_ask_conversion) {
      window.gtag_report_ask_conversion()
    }
  }

  return (
    <Container className="flats">
      <div id={`flats`} />
      <SearchContainer>
        {/* DESKTOP VIEW ***************************************** */}

        <Row className="desktop-container">
          <Col xs="12" className="no-padding desktop-list-title">
            <h1 className="no-padding">
              {intl.formatMessage({
                id: "offer.janowo.list.title",
              })}
            </h1>
          </Col>

          <Col xs={12} md={6} lg="4" xl="1" className="search-button buildings">
            <Row>
              <Col className="filter-title" xs={12}>
                <p>
                  {intl.formatMessage({
                    id: "offer.janowo.list.results.staircase",
                  })}
                </p>
              </Col>
              {optionsList.building.map(item => (
                <Col key={item.label} xs={6}>
                  <SearchButtonWrapper
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    activeFiltersCounter={activeFiltersCounter}
                    setActiveFiltersCounter={setActiveFiltersCounter}
                    data-cat={item.id}
                    addValue={handleSelectValue}
                    item={item}
                    num={"1"}
                    reset={reset}
                    className={item.value === "BONE" && "TESSTOWA"}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="search-button rooms" xs="12" md="6" lg="4" xl="1">
            <Row>
              <Fragment>
                <Col className="filter-title" xs="12">
                  <p>
                    {intl.formatMessage({
                      id: "offer.janowo.list.results.rooms",
                    })}
                  </p>
                </Col>
              </Fragment>

              {optionsList.rooms.map(item => (
                <Col key={item.label} xs="6" md={4} xl={6}>
                  <SearchButtonWrapper
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    activeFiltersCounter={activeFiltersCounter}
                    setActiveFiltersCounter={setActiveFiltersCounter}
                    data-cat={item.id}
                    num={"5"}
                    addValue={handleSelectValue}
                    reset={reset}
                    item={item}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="search-button" xs="12" md="6" lg="4" xl="2">
            <Row>
              <Col className="filter-title" xs="12">
                <p>
                  {intl.formatMessage({
                    id: "offer.janowo.list.results.space",
                  })}
                </p>
              </Col>
              {optionsList.space.map(item => (
                <Col key={item.label} xs="6">
                  <SearchButtonWrapper
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    activeFiltersCounter={activeFiltersCounter}
                    setActiveFiltersCounter={setActiveFiltersCounter}
                    data-cat={item.id}
                    item={item}
                    addValue={handleSelectValue}
                    num={"12"}
                    reset={reset}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="search-button floor" xs="12" md="3" xl="1">
            <Row>
              <Fragment>
                <Col className="filter-title" xs="12">
                  <p>
                    {intl.formatMessage({
                      id: "offer.janowo.list.results.floor",
                    })}
                  </p>
                </Col>
              </Fragment>

              {optionsList.floor.map(item => (
                <Col key={item.label} xs="6" md={3} xl={6}>
                  <SearchButtonWrapper
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    activeFiltersCounter={activeFiltersCounter}
                    setActiveFiltersCounter={setActiveFiltersCounter}
                    data-cat={item.id}
                    addValue={handleSelectValue}
                    num={"3"}
                    reset={reset}
                    item={item}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="search-button" xs="12" lg="5" xl="3">
            <Row>
              <Col className="filter-title" xs="12">
                <p>Udogodnienia</p>
              </Col>
              {optionsList.facilities.map(item => (
                <Col xs="4" key={item.label}>
                  <SearchButtonWrapper
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    activeFiltersCounter={activeFiltersCounter}
                    setActiveFiltersCounter={setActiveFiltersCounter}
                    data-cat={item.id}
                    item={item}
                    num={"6"}
                    addValue={handleSelectValue}
                    reset={reset}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="search-button" xs="12" md={4} lg="4" xl="3">
            <Row>
              <Col className="filter-title" xs="12">
                <p>Rodzaj lokalu</p>
              </Col>
              {optionsList.types.map(item => (
                <Col xs="6" lg={4} xl={6} key={item.label}>
                  <SearchButtonWrapper
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    activeFiltersCounter={activeFiltersCounter}
                    setActiveFiltersCounter={setActiveFiltersCounter}
                    data-cat={item.id}
                    item={item}
                    num={"14"}
                    addValue={handleSelectValue}
                    reset={reset}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xl="1" className="buttons-desktop">
            <MobileButton
              handleAction={handleReset}
              innerText={intl.formatMessage({
                id: "offer.janowo.list.reset.button",
              })}
              bg="rgb(93, 93, 93)"
              color="white"
              border="rgb(93, 93, 93)"
            />
            <MobileButton
              handleAction={handleSearch}
              innerText={intl.formatMessage({
                id: "offer.janowo.list.filter.button",
              })}
              bg="rgb(148, 200, 53) "
              color="white"
              border="rgb(148, 200, 53) "
            />
          </Col>
          <Col
            xs={6}
            md="5"
            xl="1"
            className="search-btn buttons-mobile filter-btn"
          >
            <MobileButton
              handleAction={handleSearch}
              innerText={intl.formatMessage({
                id: "offer.janowo.list.filter.button",
              })}
              bg="rgb(148, 200, 53) "
              color="white"
              border="rgb(148, 200, 53) "
            />
          </Col>
          <Col className="offset-col" xs="0" sm="0" md="2" />
          <Col
            xs={6}
            md="5"
            xl="1"
            className="search-btn buttons-mobile reset-btn"
          >
            <MobileButton
              handleAction={handleReset}
              innerText={intl.formatMessage({
                id: "offer.janowo.list.reset.button",
              })}
              bg="rgb(93, 93, 93)"
              color="white"
              border="rgb(93, 93, 93)"
            />
          </Col>
        </Row>

        {/* MOBILE VIEW ************************ */}

        <Row className="mobile-container">
          <Col xs="12" className="no-padding mobile-list-title">
            <h1 className="no-padding">
              {intl.formatMessage({
                id: "offer.janowo.list.title",
              })}
            </h1>
          </Col>
          <Col xs={12} className="search-btn no-padding">
            <ModalComponent activeFilters={activeFilters} search={true}>
              <MobileButton
                handleAction={() => setShowFilters(!showFilters)}
                innerText={`${intl.formatMessage({
                  id: "offer.janowo.list.filter.modal",
                })} (${activeFiltersCounter})`}
                bg="white"
                color="#B0B0B0"
                border="#B0B0B0"
              />
              <MobileContainer className="modal-filters-wrapper">
                <Col className="search-title" xs="12">
                  <h1>
                    {intl.formatMessage({
                      id: "offer.janowo.list.filter.modal",
                    })}
                  </h1>
                </Col>
                <Col className="filter-title" xs={12}>
                  <p>
                    {intl.formatMessage({
                      id: "offer.janowo.list.results.staircase",
                    })}
                  </p>
                </Col>
                <Col xs={12} className="search-button buildings">
                  <Row>
                    {optionsList.building.map(item => (
                      <Col key={item.label} xs={3}>
                        <SearchButtonWrapper
                          activeFilters={activeFilters}
                          setActiveFilters={setActiveFilters}
                          activeFiltersCounter={activeFiltersCounter}
                          setActiveFiltersCounter={setActiveFiltersCounter}
                          data-cat={item.id}
                          addValue={handleSelectValue}
                          item={item}
                          num={"1"}
                          reset={reset}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Fragment>
                  <Fragment>
                    <Col className="filter-title" xs="3">
                      <p>
                        {intl.formatMessage({
                          id: "offer.janowo.list.results.rooms",
                        })}
                      </p>
                    </Col>
                    <Col className="search-button rooms" xs="12">
                      <Row>
                        {optionsList.rooms.map(item => (
                          <Col key={item.label} xs="3">
                            <SearchButtonWrapper
                              activeFilters={activeFilters}
                              setActiveFilters={setActiveFilters}
                              activeFiltersCounter={activeFiltersCounter}
                              setActiveFiltersCounter={setActiveFiltersCounter}
                              data-cat={item.id}
                              num={"5"}
                              addValue={handleSelectValue}
                              reset={reset}
                              item={item}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Fragment>
                </Fragment>

                <Col className="filter-title" xs="12">
                  <p>
                    {intl.formatMessage({
                      id: "offer.janowo.list.results.space",
                    })}
                  </p>
                </Col>
                <Col className="search-button" xs="12">
                  <Row>
                    {optionsList.space.map(item => (
                      <Col key={item.label} xs="6">
                        <SearchButtonWrapper
                          activeFilters={activeFilters}
                          setActiveFilters={setActiveFilters}
                          activeFiltersCounter={activeFiltersCounter}
                          setActiveFiltersCounter={setActiveFiltersCounter}
                          data-cat={item.id}
                          item={item}
                          addValue={handleSelectValue}
                          num={"12"}
                          reset={reset}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Fragment>
                  <Col className="filter-title" xs="3">
                    <p>
                      {intl.formatMessage({
                        id: "offer.janowo.list.results.floor",
                      })}
                    </p>
                  </Col>

                  <Col className="search-button floor" xs="12">
                    <Row>
                      {optionsList.floor.map(item => (
                        <Col key={item.label} xs="3">
                          <SearchButtonWrapper
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            activeFiltersCounter={activeFiltersCounter}
                            setActiveFiltersCounter={setActiveFiltersCounter}
                            data-cat={item.id}
                            addValue={handleSelectValue}
                            num={"3"}
                            reset={reset}
                            item={item}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Fragment>

                <Col className="filter-title" xs="12">
                  <p>Udogodnienia</p>
                </Col>
                <Col className="search-button" xs="12">
                  <Row>
                    {optionsList.facilities.map(item => (
                      <Col key={item.label} xs="6">
                        <SearchButtonWrapper
                          activeFilters={activeFilters}
                          setActiveFilters={setActiveFilters}
                          activeFiltersCounter={activeFiltersCounter}
                          setActiveFiltersCounter={setActiveFiltersCounter}
                          data-cat={item.id}
                          item={item}
                          num={"6"}
                          addValue={handleSelectValue}
                          reset={reset}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col className="filter-title" xs="12">
                  <p>Rodzaj lokalu</p>
                </Col>
                <Col className="search-button" xs="12">
                  <Row>
                    {optionsList.types.map(item => (
                      <Col key={item.label} xs="6">
                        <SearchButtonWrapper
                          activeFilters={activeFilters}
                          setActiveFilters={setActiveFilters}
                          activeFiltersCounter={activeFiltersCounter}
                          setActiveFiltersCounter={setActiveFiltersCounter}
                          data-cat={item.id}
                          item={item}
                          num={"14"}
                          addValue={handleSelectValue}
                          reset={reset}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </MobileContainer>
              <Col xs={12} className="search-btn reset-btn">
                <MobileButton
                  handleAction={() => handleSearch()}
                  innerText={intl.formatMessage({
                    id: "offer.janowo.list.filter.button",
                  })}
                  bg="rgb(148, 200, 53)"
                  color="white"
                  border="rgb(148, 200, 53)"
                />
              </Col>
              <Col xs={12} className="search-btn reset-btn">
                <MobileButton
                  handleAction={() => handleReset()}
                  innerText={intl.formatMessage({
                    id: "offer.janowo.list.reset.button",
                  })}
                  bg="rgb(93, 93, 93)"
                  color="white"
                  border="rgb(93, 93, 93)"
                />
              </Col>
            </ModalComponent>
          </Col>
        </Row>

        <div className="container-border">
          <h2>
            {results.length === list.length
              ? intl.formatMessage({
                  id: "offer.janowo.list.results.number.all",
                })
              : intl.formatMessage({
                  id: "offer.janowo.list.results.number",
                })}{" "}
            ({results.length}){" "}
            {results &&
              results.length < 1 &&
              intl.formatMessage({
                id: "offer.janowo.list.results.changeFilters",
              })}
          </h2>
          <Row className="legend-info">
            <Col xl={1}>
              {intl.formatMessage({
                id: "offer.janowo.list.results.id",
              })}
            </Col>
            <Col xl="1" style={{ paddingLeft: "15px" }}>
              {intl.formatMessage({
                id: "offer.janowo.list.results.staircase",
              })}
            </Col>
            <Col xl="1">
              {intl.formatMessage({
                id: "offer.janowo.list.results.floor",
              })}
            </Col>
            <Col xl="1">
              {intl.formatMessage({
                id: "offer.janowo.list.results.space",
              })}
            </Col>
            <Col xl="1">
              {intl.formatMessage({
                id: "offer.janowo.list.results.rooms",
              })}
            </Col>
            <Col xl>Udogodnienia</Col>
            <Col xl>Rodzaj lokalu</Col>
            <Col
              xl
              style={{
                lineHeight: 1,
              }}
            >
              Planowany termin odbioru
            </Col>
            <Col xl={3}>
              <Row style={{ padding: 0 }} className={"text-center"}>
                <Col style={{ padding: 0 }}>3D</Col>
                <Col style={{ padding: 0 }}>PDF</Col>
                <Col style={{ padding: 0 }}>CENA</Col>
              </Row>
              {/* {intl.formatMessage({
                id: "offer.janowo.list.options.card",
              })}*/}
            </Col>
          </Row>

          <div
            style={{
              height: results.length < 1 && "0",
              overflow: "hidden",
              marginBottom: "50px",
            }}
          >
            {results && results.length > 0 ? (
              <PoseGroup>
                <ShowMore items={results} by={10} key="1">
                  {({ current, onMore }) => (
                    <Fragment>
                      {current.map((item, i) => (
                        <PoseWrapper key={i} i={i}>
                          <FlatItemContainer>
                            <Fragment>
                              <Accordion
                                className={`${item[13] === "" ? "" : "sold"}`}
                                defaultActiveKey=""
                              >
                                <div>
                                  <Accordion.Toggle as={"div"} eventKey="0">
                                    <div className={`mobile-results `}>
                                      <Col
                                        xs="12"
                                        className="main-results no-padding"
                                      >
                                        <div className="results-item ">
                                          {item[0]}
                                        </div>
                                        <div className="results-item">
                                          {item[3]}
                                        </div>
                                        <div className="results-item id-item">
                                          {parseFloat(item[4]).toFixed(2)}m
                                          <sup>2</sup>
                                        </div>
                                      </Col>
                                    </div>

                                    <div
                                      className={`desktop-results ${
                                        item[13] === "" ? "" : "sold"
                                      }`}
                                    >
                                      <Row>
                                        <Col
                                          xs={1}
                                          className="no-padding table-result-item"
                                        >
                                          <div className="results-item  id-item">
                                            {item[0]}
                                          </div>
                                        </Col>

                                        <Col
                                          xs="1"
                                          className="table-result-item"
                                        >
                                          <div
                                            className="results-item "
                                            style={{ paddingLeft: "15px" }}
                                          >
                                            {item[1] === "BONE"
                                              ? "B1"
                                              : item[1]}
                                          </div>
                                        </Col>

                                        <Col
                                          xs={"1"}
                                          className="table-result-item"
                                        >
                                          <div className="results-item ">
                                            {item[3]}
                                          </div>
                                        </Col>

                                        <Col
                                          className="table-result-item"
                                          xs={"1"}
                                        >
                                          <div className="results-item ">
                                            {parseFloat(item[4]).toFixed(2)}m
                                            <sup>2</sup>
                                          </div>
                                        </Col>

                                        <Col
                                          xs="1"
                                          className="table-result-item"
                                        >
                                          <div className="results-item ">
                                            {item[5]}{" "}
                                            {intl.formatMessage({
                                              id:
                                                "offer.janowo.list.options.rooms.short",
                                            })}
                                          </div>
                                        </Col>

                                        <Col
                                          className="no-padding table-result-item sides-mobile"
                                          xs
                                        >
                                          <div className="results-item  ">
                                            <div>
                                              {item[6]
                                                .split(",")
                                                .map((i, index) => (
                                                  <span
                                                    key={`${i + Math.random()}`}
                                                  >
                                                    {index > 0 ? ", " : ""}
                                                    {i.trim()}
                                                  </span>
                                                ))}
                                            </div>
                                          </div>
                                        </Col>

                                        <Col xs className="table-result-item">
                                          <div className="results-item ">
                                            {intl.formatMessage({
                                              id: `offer.puck.list.options.type.${item[14]}`,
                                            })}
                                          </div>
                                        </Col>

                                        <Col
                                          className="no-padding date-mobile table-result-item"
                                          xs
                                        >
                                          <div className="results-item  date">
                                            <span></span>{" "}
                                            {item[13] === ""
                                              ? item[7]
                                              : item[7]}
                                          </div>
                                        </Col>
                                        <Col
                                          className="no-padding table-result-item"
                                          xs={"3"}
                                        >
                                          <Row>
                                            <Col className="results-item details options">
                                              <div
                                                className="results-item"
                                                style={{
                                                  margin: 0,
                                                  padding: 0,
                                                  width: "50%",
                                                }}
                                              >
                                                {item.tour ? (
                                                  <ModalComponent
                                                    className={"full-modal"}
                                                  >
                                                    <MobileButton
                                                      handleAction={() => {
                                                        // setActiveTour(i)
                                                      }}
                                                      bg="#eaeaea"
                                                      border="transparent"
                                                      arrow={false}
                                                      smallArrow={false}
                                                    >
                                                      {/*<img
                                                        src={img360}
                                                        alt={"360"}
                                                        width={32}
                                                        height={32}
                                                      />*/}
                                                      Spacer 3d
                                                    </MobileButton>
                                                    <iframe
                                                      src={item.tour}
                                                      width={"100%"}
                                                      height={
                                                        typeof window !==
                                                        "undefined"
                                                          ? `${window.innerHeight *
                                                              0.9}px`
                                                          : "100%"
                                                      }
                                                    />
                                                  </ModalComponent>
                                                ) : (
                                                  <MobileButton
                                                    bg="#eee"
                                                    color="black"
                                                    border="transparent"
                                                    arrow={false}
                                                    smallArrow={false}
                                                    disabled
                                                  >
                                                    Spacer 3d
                                                  </MobileButton>
                                                )}
                                              </div>
                                              {/*</Col>*/}
                                              {/*<Col className="results-item table-result-item details">*/}
                                              <div
                                                className={
                                                  "results-item details options"
                                                }
                                              >
                                                <a
                                                  className="results-item"
                                                  href={item[11]}
                                                  target={"_blank"}
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    width: "50%",
                                                  }}
                                                >
                                                  <MobileButton
                                                    handleAction={() => item}
                                                    innerText={intl.formatMessage(
                                                      {
                                                        id:
                                                          "offer.janowo.list.options.card",
                                                      }
                                                    )}
                                                    bg="#5D5D5D"
                                                    color="white"
                                                    border="transparent"
                                                    arrow={false}
                                                    smallArrow={false}
                                                  />
                                                </a>
                                                {/*</Col>*/}
                                                {/*<Col style={{ margin: "auto" }}>*/}
                                                <div
                                                  className="results-item"
                                                  onClick={() => addID(item[0])}
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    width: "50%",
                                                  }}
                                                >
                                                  {/*{item[13] === "" ? (*/}
                                                  <MobileButton
                                                    handleAction={() => {
                                                      document.getElementById(
                                                        "myModal"
                                                      ).style = {
                                                        display: "block",
                                                      }
                                                      document.getElementsByClassName(
                                                        "code"
                                                      )[0].innerText = item[0]
                                                      document.getElementById(
                                                        "projectName"
                                                      ).value = estate
                                                      gtag_report_ask_conversion()
                                                    }}
                                                    bg={
                                                      item[13] !== ""
                                                        ? "#dadada"
                                                        : item.price
                                                        ? "black"
                                                        : "#94C835"
                                                    }
                                                    color={
                                                      item[13] === ""
                                                        ? "white"
                                                        : "black"
                                                    }
                                                    border={"transparent"}
                                                    arrow={false}
                                                    smallArrow={false}
                                                    className={
                                                      item[13] ? "clamped" : ""
                                                    }
                                                  >
                                                    {item[13] === "" ? (
                                                      `${item.price ??
                                                        intl.formatMessage({
                                                          id:
                                                            "offer.janowo.list.options.askForPrice",
                                                        })}`
                                                    ) : (
                                                      <>
                                                        Zarezerwowany{" "}
                                                        <small>
                                                          Zapytaj o podobne
                                                        </small>
                                                      </>
                                                    )}
                                                  </MobileButton>
                                                  {/* ) : (
                                                    <MobileButton
                                                      className={"m-0"}
                                                      innerText={
                                                        "Zarezerwowany"
                                                      }
                                                      border={"transparent"}
                                                      arrow={false}
                                                      bg={"#dadada"}
                                                    />
                                                  )}*/}
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Accordion.Toggle>
                                  <div className={"mobile-only"}>
                                    <Accordion.Collapse eventKey="0">
                                      <Row>
                                        <Col
                                          className="no-padding sides-desktop"
                                          xs="12"
                                        >
                                          <div className="results-item ">
                                            <div className="sides">
                                              {item[6].split(",").map(item => (
                                                <div
                                                  key={`${item +
                                                    Math.random()}`}
                                                >
                                                  {item}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </Col>

                                        <Col
                                          className="no-padding date-desktop"
                                          xs="12"
                                        >
                                          <div className="results-item date">
                                            <span />{" "}
                                            {item[13] === "" && item[7]}
                                          </div>
                                        </Col>
                                        <Col
                                          xs="0"
                                          sm="0"
                                          md="0"
                                          // lg={item[13] === "" ? "6" : "8"}
                                          lg="2"
                                          xl="4"
                                          className="btns-offset"
                                        />
                                        <Col
                                          className="no-padding options-btn"
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          {item.tour && (
                                            <div className="results-item">
                                              <ModalComponent
                                                className={"full-modal"}
                                              >
                                                <MobileButton
                                                  handleAction={() => {
                                                    // setActiveTour(i)
                                                  }}
                                                  innerText={intl.formatMessage(
                                                    {
                                                      id:
                                                        "offer.janowo.list.options.tour",
                                                    }
                                                  )}
                                                  bg="#eaeaea"
                                                  border="#eaeaea"
                                                  arrow={true}
                                                  smallArrow={true}
                                                />
                                                <iframe
                                                  src={item.tour}
                                                  width={"100%"}
                                                  height={
                                                    typeof window !==
                                                    "undefined"
                                                      ? `${window.innerHeight *
                                                          0.9}px`
                                                      : "100%"
                                                  }
                                                />
                                              </ModalComponent>
                                            </div>
                                          )}
                                        </Col>
                                        <Col
                                          className="no-padding options-btn"
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          <div
                                            className="results-item"
                                            // href={"budynek"}
                                          >
                                            {/*<Scroller
                                            activeClass="active"
                                            to={"plan"}
                                            spy={true}
                                            smooth={true}
                                            offset={50}
                                            duration={500}
                                          >
                                            <MobileButton
                                              handleAction={() => item}
                                              innerText={intl.formatMessage({
                                                id:
                                                  "offer.janowo.list.options.localization",
                                              })}
                                              bg="#DADADA"
                                              border="#DADADA"
                                              arrow={true}
                                              smallArrow={true}
                                            />
                                          </Scroller>*/}
                                          </div>
                                        </Col>
                                        <Col
                                          className="no-padding options-btn"
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          <a
                                            className="results-item"
                                            href={item[11]}
                                            target={"_blank"}
                                            rel="noopener noreferrer"
                                          >
                                            <MobileButton
                                              handleAction={() => item}
                                              innerText={intl.formatMessage({
                                                id:
                                                  "offer.janowo.list.options.card",
                                              })}
                                              bg="#5D5D5D"
                                              color="white"
                                              border="#5D5D5D"
                                              arrow={true}
                                              smallArrow={true}
                                            />
                                          </a>
                                        </Col>
                                        <Col
                                          className={`mobile-results mobile-results-button no-padding options-btn ${
                                            item[13] === "" ? "" : "sold"
                                          }`}
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          <div
                                            className="results-item"
                                            onClick={() => addID(item[0])}
                                          >
                                            {item[13] === "" && (
                                              <MobileButton
                                                handleAction={() => {
                                                  document.getElementById(
                                                    "myModal"
                                                  ).style = { display: "block" }
                                                  document.getElementsByClassName(
                                                    "code"
                                                  )[0].innerText = item[0]
                                                  document.getElementById(
                                                    "projectName"
                                                  ).value = estate
                                                  gtag_report_ask_conversion()
                                                }}
                                                innerText={
                                                  item[13] === ""
                                                    ? `${item.price ??
                                                        intl.formatMessage({
                                                          id:
                                                            "offer.janowo.list.options.askForPrice",
                                                        })}`
                                                    : "Zarezerwowany"
                                                }
                                                bg={
                                                  item[13] !== ""
                                                    ? "transparent"
                                                    : item.price
                                                    ? "black"
                                                    : "#94C835"
                                                }
                                                color={
                                                  item[13] === ""
                                                    ? "white"
                                                    : "black"
                                                }
                                                arrow={true}
                                                border={"transparent"}
                                                smallArrow={true}
                                              />
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Accordion.Collapse>
                                  </div>
                                </div>
                              </Accordion>
                            </Fragment>
                          </FlatItemContainer>
                        </PoseWrapper>
                      ))}
                      <Row>
                        <Col className="no-padding show-more" xs="12">
                          {onMore ? (
                            <MobileButton
                              innerText={intl.formatMessage({
                                id: "offer.janowo.list.results.more",
                              })}
                              bg="#94C835"
                              color="white"
                              border="#94C835"
                              arrow={false}
                              disabled={!onMore}
                              handleAction={() => {
                                if (!!onMore) onMore()
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </ShowMore>
              </PoseGroup>
            ) : (
              <div key="brak"></div>
            )}

            {/*<Row>
              <Col
                className="no-padding"
                xs="12"
                style={{ textAlign: "center", margin: "auto" }}
              >
                Posiadamy{" "}
                <strong>
                  miejsca postojowe w hali garażowej, miejsca zewnętrzne
                </strong>{" "}
                oraz <strong>komórki lokatorskie</strong> w pierwszym etapie
                inwestycji Janowo Park.
                <br />
                Zainteresowanych prosimy o kontakt z biurem sprzedaży.
              </Col>
            </Row>*/}
          </div>
        </div>
        <Row>
          {results && results.length < 1 && (
            <Col xs="12" className="no-results">
              <div>
                <Fragment></Fragment>
              </div>
            </Col>
          )}
        </Row>
      </SearchContainer>
    </Container>
  )
}

export function SearchButtonWrapper({
  item,
  addValue,
  num,
  reset,
  setActiveFilters,
  activeFilters,
  activeFiltersCounter,
  setActiveFiltersCounter,
}) {
  useEffect(() => {}, [reset])

  return (
    <Button
      className={
        activeFilters.hasOwnProperty(item.value)
          ? `search-filter active ${item.label}`
          : `search-filter ${item.label}`
      }
      variant={false}
      onClick={e => {
        addValue(e, num, item.value, item.id)
        setActiveFiltersCounter(
          e.target.className.includes("active")
            ? (activeFiltersCounter -= 1)
            : (activeFiltersCounter += 1)
        )
        setActiveFilters(
          activeFilters && activeFilters.hasOwnProperty(item.value)
            ? () => {
                delete activeFilters[item.value]
                return activeFilters
              }
            : { ...activeFilters, [item.value]: item.value }
        )
      }}
      data-cat={item.id}
    >
      {item.label}
    </Button>
  )
}

export default injectIntl(Search)
